import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProductdetailPage } from './productdetail/productdetail.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'categories',
    loadChildren: () =>
    import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'categories/walker',
    loadChildren: () =>
      import('./categories/walker/walker.module').then((m) => m.WalkerPageModule),
  },
  {
    path: 'categories/walker/walker-without-wheel-rental',
    loadChildren: () =>
      import('./categories/walker/without-wheel-rental/without-wheel-rental.module').then((m) => m.WithoutWheelRentalPageModule),
  },
  {
    path: 'categories/walker/walker-without-wheel-sale',
    loadChildren: () =>
      import('./categories/walker/without-wheel-sale/without-wheel-sale.module').then((m) => m.WithoutWheelSalePageModule),
  },
  {
    path: 'categories/walker/walker-with-wheel-rental',
    loadChildren: () =>
      import('./categories/walker/with-wheel-rental/with-wheel-rental.module').then((m) => m.WithWheelRentalPageModule),
  },
  {
    path: 'categories/walker/walker-with-wheel-sale',
    loadChildren: () =>
      import('./categories/walker/with-wheel-sale/with-wheel-sale.module').then((m) => m.WithWheelSalePageModule),
  },
  {
    path: 'categories/walker/walker-with-big-wheel-sale',
    loadChildren: () =>
      import('./categories/walker/with-big-wheel-sale/with-big-wheel-sale.module').then((m) => m.WithBigWheelSalePageModule),
  },
  {
    path: 'categories/walker/walker-with-big-wheel-rental',
    loadChildren: () =>
      import('./categories/walker/with-big-wheel-rental/with-big-wheel-rental.module').then((m) => m.WithBigWheelRentalPageModule),
  },
  {
    path: 'categories/hospitalbed',
    loadChildren: () =>
      import('./categories/hospitalbed/hospitalbed.module').then((m) => m.HospitalbedPageModule),
  },
  {
    path: 'categories/wheelchair',
    loadChildren: () =>
      import('./categories/wheelchair/wheelchair.module').then((m) => m.WheelchairPageModule),
  },
  // {
  //   path: 'categories/wheelchair/standard-wheelchair-rental',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/standard-wheelchair-rental/standard-wheelchair-rental.module').then((m) => m.StandardWheelchairRentalPageModule),
  // },
  // {
  //   path: 'categories/wheelchair/standard-wheelchair-sale',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/standard-wheelchair-sale/standard-wheelchair-sale.module').then((m) => m.StandardWheelchairSalePageModule),
  // },
  // {
  //   path: 'categories/wheelchair/light-weight-wheelchair-rental',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/light-weight-wheelchair-rental/light-weight-wheelchair-rental.module').then((m) => m.LightWeightWheelchairRentalPageModule),
  // },
  // {
  //   path: 'categories/wheelchair/light-weight-wheelchair-sale',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/light-weight-wheelchair-sale/light-weight-wheelchair-sale.module').then((m) => m.LightWeightWheelchairSalePageModule),
  // },
  // {
  //   path: 'categories/wheelchair/recliner-wheelchair-rental',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/recliner-wheelchair-rental/recliner-wheelchair-rental.module').then((m) => m.ReclinerWheelchairRentalPageModule),
  // },
  // {
  //   path: 'categories/wheelchair/recliner-wheelchair-sale',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/recliner-wheelchair-sale/recliner-wheelchair-sale.module').then((m) => m.ReclinerWheelchairSalePageModule),
  // },
  // {
  //   path: 'categories/wheelchair/automatic-wheelchair-rental',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/automatic-wheelchair-rental/automatic-wheelchair-rental.module').then((m) => m.AutomaticWheelchairRentalPageModule),
  // },
  // {
  //   path: 'categories/wheelchair/automatic-wheelchair-sale',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/automatic-wheelchair-sale/automatic-wheelchair-sale.module').then((m) => m.AutomaticWheelchairSalePageModule),
  // },
  // {
  //   path: 'categories/wheelchair/wheelchair-with-commode-sale',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/wheelchair-with-commode-sale/wheelchair-with-commode-sale.module').then((m) => m.WheelchairWithCommodeSalePageModule),
  // },
  // {
  //   path: 'categories/wheelchair/tricycle-sale',
  //   loadChildren: () =>
  //     import('./categories/wheelchair/tricycle-sale/tricycle-sale.module').then((m) => m.TricycleSalePageModule),
  // },
  {
    path: 'detail/:id',
    component: ProductdetailPage
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'order',
    loadChildren: () =>
      import('./order/order.module').then((m) => m.OrderPageModule),
  },
  {
    path: 'orderhistory',
    loadChildren: () =>
      import('./orderhistory/orderhistory.module').then(
        (m) => m.OrderhistoryPageModule
      ),
  },
  {
    path: 'nursingcare',
    loadChildren: () => import('./nursingcare/nursingcare.module').then( m => m.NursingcarePageModule)
  },
  {
    path: 'categories/walker',
    loadChildren: () => import('./categories/walker/walker.module').then( m => m.WalkerPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'product-redirect',
    loadChildren: () => import('./product-redirect/product-redirect.module').then(
      (m) => m.ProductRedirectPageModule
    )
  },
  {
    path: 'productdetail/:id', 
    loadChildren: () => import('./product-redirect/product-redirect.module').then(m => m.ProductRedirectPageModule)
  },
    {
    path: 'productdetail',
    loadChildren: () =>
      import('./productdetail/productdetail.module').then(
        (m) => m.ProductdetailPageModule
      ),
  },
  {
    path: '**',
    redirectTo: '/tabs/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
