import {
  Component,
  Input,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IonSearchbar } from '@ionic/angular';
import { throws } from 'assert';
import { Quali5carelibService } from 'quali5care-lib';
import { AlertuiService } from 'src/app/alertui.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() showMenu: string;
  @Input() flat: string;
  @Input() showProducts: string;
  @Input() showSearch: string;
  @Input() menu: string;

  @ViewChild('mySearchbar', { static: false }) searchbar: IonSearchbar;

  @Output() modechanged = new EventEmitter<String>();

  strsearch;

  showcategories;
  insidecategories;
  curmode = 'RENT';
  rentcolor;
  location; 

  constructor(private _router: Router, public quali5lib: Quali5carelibService) {
    this.showMenu = 'false';
    this.menu = 'false';
    this.flat = 'false';
    this.showProducts = 'true';
    this.showSearch = 'true';
    
  }

  setCurrentMode() {
    this.location = localStorage.getItem("selectedcity");
    if (this.quali5lib.dataService.globalvars) {
      var curmode = this.quali5lib.dataService.globalvars.get('currentmode');
      if (curmode) this.curmode = curmode;
      else this.curmode = 'RENT';
    }
  }

  getProductlist() {
    var retlist = [];

    for (var prod of this.quali5lib.dataService.productlist) {
      if ((prod.billingtype == this.curmode || prod.billingtype == 'BOTH') && (!prod.hideonweb))
        retlist.push(prod);
    }

    return retlist;
  }

  ngOnInit() {
    console.log('HEADER INIT..');
    this.rentcolor = 'background-color: darkblue';
    this.setCurrentMode();

    //console.log(JSON.stringify(this.quali5lib.dataService.productlist));
  }

  ionViewWillEnter() {
    console.log('HEADER VIEW ENTER..>>>> ' + localStorage.getItem("selectedcity"));
    this.setCurrentMode();
   
    
  }

  opennot() {
    this._router.navigate(['/notifications']);
  }

  gotohome() {
    console.log('GOING HOME...')
    this._router.navigate(['/']);
  }

  gotocart() {
    this._router.navigate(['/tabs/cart']);
  }

  gotouser() {
    this._router.navigate(['/tabs/user']);
  }
  enterButton() {
    this.showcategories = true;
  }

  exitButton() {
    setTimeout(() => {
      this.disableCategories();
    }, 1000);
  }
  disableCategories() {
    if (!this.insidecategories) this.showcategories = false;
  }

  changeMode(newmode) {
    
    var oldmode = this.curmode; 
    if(oldmode != newmode){
      this.curmode = newmode;
      this.quali5lib.dataService.globalvars.set('currentmode', newmode);
      this.modechanged.emit('modechanged');
    }
  }

  gotoproduct(prod) {
    this.strsearch = '';
    this.searchbar.value = null;
    this.quali5lib.dataService.globalvars.set('selectedproduct', prod);
    this._router.navigate(['/productdetail']);

    var proddetail =
      this.quali5lib.dataService.globalvars.get('detailinstance');
    if (proddetail) {
      console.log('CALLING INIT');
      proddetail.callinit();
    }
  }

  ionChange(event) {
    this.strsearch = event.detail.value;
  }
}
