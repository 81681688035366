import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertuiService {
  private data = [];
  constructor(
    public alertController: AlertController,
    public toastController: ToastController
  ) {}

  public async showError(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      cssClass: 'warning-toast',
      duration: 2000,
    });
    toast.present();
  }

  addItem(item) {
    this.data = item;
  }

  getItems() {
    return this.data;
  }
}
