import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertuiService } from '../alertui.service';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { NavigationEnd, Router } from '@angular/router';
import { Product } from 'quali5care-lib/domain/setup/product';
import { Quali5carelibService } from 'quali5care-lib';
import { HeaderComponent } from '../components/header/header.component';
import { IonContent } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { Functionresponse } from 'quali5care-lib/domain/commons/functionresponse';
import { Cart } from 'quali5care-lib/domain/ordering/cart';
import { Customer } from 'quali5care-lib/domain/setup/customer';
import { Productcategory } from 'quali5care-lib/domain/setup/productcategory';
import { TimeService } from '../time.service';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);
declare let gtag: Function;

import { TouchSequence } from 'selenium-webdriver';
import { IonModal } from '@ionic/angular';

@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.page.html',
  styleUrls: ['./productdetail.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductdetailPage implements OnInit {
  @ViewChild('swiper') swiper: SwiperComponent;
  @ViewChild('ionContent', { static: false }) ionContent: IonContent;
  showmodal: boolean;
  canDismiss: boolean;
  @ViewChild('locationmodal') locationmodal: IonModal;
  productDetail: any = [];

  thumbnail = [
    { imageurl: '../../assets/RESOURCES/products/HOSPITAL BED.png' },
    { imageurl: '../../assets/RESOURCES/products/HOSPITAL BED.png' },
    {
      sources:
        'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',

      thumb:
        'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/ForBiggerMeltdowns.jpg',
    },
  ];

  videofile = [
    {
      sources: [
        'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
      ],
      thumb:
        'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/ForBiggerMeltdowns.jpg',
    },
  ];

  delcharge = 999;
  setupcharge = 500;
  showcategories;
  insidecategories;
  activecart = 'RENT';
  @ViewChild(HeaderComponent)
  appheader: HeaderComponent;
  productCategories: Productcategory[];
  isReadMore = true;
  products: Product[];
  featuredProd: any = [];
  currentTime: Date;
  isBeforeNoon: boolean;
  afterNoon: boolean;
  private timeUpdateInterval: any;
  remainingTime: string;
  constructor(
    private _router: Router,
    public alertService: AlertuiService,
    public quali5lib: Quali5carelibService,
    public toastController: ToastController,
    private timeService: TimeService
  ) {
    console.log('|CALLS|CONSTRUCTOR CALLED ');

    /*if (!this.quali5lib.dataService.isloggedin) {
      this._router.navigate(['/']);
    }*/
    this.timeService.isBeforeNoon$.subscribe((value) => {
      this.isBeforeNoon = value;
      this.afterNoon = !value; // Set the 'afterNoon' property based on 'isBeforeNoon'
    });
    this.timeService.timeUntilNoon$.subscribe((value) => {
      this.remainingTime = value;
    });
    this.updateCurrentTime();
    this.timeUpdateInterval = setInterval(() => this.updateCurrentTime(), 1000);
    

    this.currentTime = this.timeService.getCurrentTime();
  }

  curmode = 'RENT';

  changeMode(newmode) {
    console.log('Show cats ' + this.showcategories);

    this.appheader.curmode = newmode;
    this.quali5lib.dataService.globalvars.set('currentmode', newmode);
    this.curmode = newmode;

    this.showcategories = false;
    console.log('Show cats ' + this.showcategories);
  }

  item: Product;
  prodqty: any;
  relatedProducts: Product[];

  productdetail(prod) {
    this.quali5lib.dataService.globalvars.set('selectedproduct', prod);
    //this._router.navigate(['/productdetail']);
    this.callinit();
    this.ionContent.scrollToTop(1000);

    //this.alertService.addItem(data);
  }

  callinit() {
    this.item = this.quali5lib.dataService.globalvars.get('selectedproduct');
    if (this.item && this.item.id) {
      // This changes the URL without causing navigation
      history.replaceState({}, '', `/productdetail/${this.item.id}`);
    }
    if (
      (this.item.imagefiles && this.item.imagefiles.length > 1) ||
      this.item.demovideourl
    )
      this.colsize = 10;
    else this.colsize = 12;

    console.log(this.item);
    if (!this.item) this._router.navigate(['/']);
    this.selectRentPeriod('1m');
    //this.productDetail = [];
    this.products = this.quali5lib.dataService.productlist;
    this.showcategories = false;

    for (let index = 0; index < 6; index++) {
      this.featuredProd.push(this.products[index]);
    }

    if (!this.quali5lib.dataService.globalvars) this._router.navigate(['/']);
    this.setCurrentMode();

    this.relatedProducts = [];
    if (
      this.item.productsboughttogether &&
      this.item.productsboughttogether.length > 0
    ) {
      for (var prodid of this.item.productsboughttogether) {
        var prod = this.quali5lib.dataService.getProductForId(prodid);
        if (
          prod &&
          (prod.billingtype == this.curmode || prod.billingtype == 'BOTH') && (!prod.hideonweb)
        )
          this.relatedProducts.push(prod);
        if (this.relatedProducts.length > 4) {
          this.prodqty = true;
        } else {
          this.prodqty = false;
        }
      }
    }

    //sEtting the instance
    this.quali5lib.dataService.globalvars.set('detailinstance', this);
  }
  colsize = 10;
  showvideo;
  location;
  ionViewWillEnter() {
    // this.ionContent.scrollToTop(1000);   
    this.productCategories = [];
    for (var item of this.quali5lib.dataService.categorylist) {
      if (!item.hideonweb) this.productCategories.push(item)
    }
    this.location = localStorage.getItem('selectedcity');
    if (!this.location){
      this.showmodal = true;
      this.loc = 'Mumbai';
    }
    // this.showvideo = false;
    console.log(this.quali5lib.dataService.categorylist);
    //if(!this.fromlink){
    var curmode = this.quali5lib.dataService.globalvars.get('currentmode');
    if (curmode) this.appheader.curmode = curmode;

    console.log('************** NOT FROM LINK.. CALL INIIT VIEW ENTER');
    this.callinit();
    this.curindex = 0;

    //}
  }

  fromlink;
  prodrefid;
  isRunning;
  value;

  async setLocalData() {
    var products = this.quali5lib.dataService.productlist;

    for (var prod of products) {
      if (prod.id == this.prodrefid) {
        this.quali5lib.dataService.globalvars.set('selectedproduct', prod);
        //this.item = prod;
        this.callinit();
        var eventname = 'adopened:' + this.item.name;
        gtag('config', 'G-T2VM74HYYM');
        gtag('event', eventname);

        break;
      }
    }

    if (!this.item) {
      this._router.navigate(['/']);
      return;
    }

    this.setCurrentMode();

    var custmobile = localStorage.getItem('customermobile');
    if (custmobile) {
      var cust = <Customer>(
        await this.quali5lib.loginService.findCustomerByMobile(custmobile)
      );
      if (cust) {
        this.quali5lib.dataService.loggedincustomer = cust;
      }
    }
  }

  monitorDataInitialization() {
    var resp = <Functionresponse>(
      this.quali5lib.dataService.getInitMasterStatusCustomer()
    );

    switch (resp.status) {
      case 'error':
        this.alertService.showError(
          'Error initializing data, please try again!'
        );
        return;
      case 'success':
        //For now initlaie cart
        var storedcart = false;
        var curCart = localStorage.getItem('cart');
        if (curCart) {
          var cart = <Cart>JSON.parse(curCart);
          if (cart && cart.sumitemcount > 0) {
            this.quali5lib.orderService.cart = cart;
            storedcart = true;
          }
        }

        if (!storedcart) this.quali5lib.orderService.initializeCart();
        this.quali5lib.dataService.isloggedin = true;
        this.isRunning = false;

        this.setLocalData();
        return;
      case 'inprogress':
        this.value = 20 + Math.round((resp.statuserrorcode * 80) / 100);
    }
    //Monitor recursively
    setTimeout(() => {
      this.monitorDataInitialization();
    }, 50);

    //$crisp.push(['do', 'chat:open']);
  }

  async login() {
    try {
      this.value = 15;
      this.isRunning = true;
      //const user = await Auth.signIn(this.useremail, this.userpass);
      this.value = 20;
      //const credentials = await Auth.currentCredentials();

      //Set the credentails
      this.quali5lib.dataService.bootstrapParams(
        "",
        "website",
        -1,
        'dev',
        'q5care'
      );
      this.quali5lib.dataService.initMasterDataCustomer();

      this.monitorDataInitialization();
    } catch (err) {
      console.log('Error signing in ' + err);
      this._router.navigate(['/']);
    }
  }

  useremail;
  userpass;

  ngOnInit() {
    /*var currentURL = window.location.href;
      console.log('CURRENT URL = ' + currentURL);
      if (currentURL.indexOf('prodref') >= 0) {
          var data = currentURL.substring(currentURL.indexOf('prodref') + 8,currentURL.length);
          console.log('DATA' + data);
          if (data && data.length > 2) {
            this.fromlink = true;

            this.useremail = 'admin';
            this.userpass = 'admin123';
            this.prodrefid = data;
            console.log('***prod refid = ' + this.prodrefid);

            this.login();
          }
      }*/

    /*if(window['prodref'] != null){
        this.prodrefid = window['prodref'];
        console.log('PRODREF ' + this.prodrefid);
        this.fromlink = true;

        //this.useremail = 'admin';
        //this.userpass = 'admin123';
        
        console.log('***prod refid = ' + this.prodrefid);
        window['prodref'] = null;
       //this.login();
       this.setLocalData();
      }*/

    if (!this.fromlink) {
      console.log('************** NOT FROM LINK..');
      if (!this.quali5lib.dataService.isloggedin) {
        this._router.navigate(['/']);
      } else {
        this.callinit();
      }
    }
  }

  gotohome() {
    this._router.navigate(['']);
  }

  showText() {
    this.isReadMore = !this.isReadMore;
  }

  ngAfterContentChecked() {
    if (this.swiper) {
      this.swiper.updateSwiper({});
    }
  }

  curindex;

  setCurrentMode() {
    var curmode = this.quali5lib.dataService.globalvars.get('currentmode');
    if (curmode) this.curmode = curmode;
    else this.curmode = 'RENT';

    if (this.appheader) this.appheader.setCurrentMode();
  }

  onModechanged(event) {
    this.goBack();
    //this.setCurrentMode();
  }

  addtocart() {
    var eventname =
      'addedtocart:' +
      this.item.name +
      '-' +
      this.item.brandname +
      '-' +
      this.item.modelno;
    gtag('event', eventname);

    this.quali5lib.orderService.addItemtoCart(
      this.item.id,
      this.curmode,
      this.selectedPeriod
    );
    var msg = this.item.name + ' added to your cart.';
    this.displayToast(msg);
    this._router.navigate(['/tabs/cart']);
  }

  enterCategories() {
    console.log('|HOVER CAT IN++|');
    this.appheader.showcategories = true;
    this.appheader.insidecategories = true;
    console.log('|HOVER CAT IN--|');
  }

  exitCategories() {
    console.log('|HOVER CAT OUT++|');
    this.appheader.showcategories = false;
    this.appheader.insidecategories = false;
    console.log('|HOVER CAT OUT--|');
  }

  disableCategories() {
    if (!this.insidecategories) this.showcategories = false;
  }
  exitButton() {
    setTimeout(() => {
      this.disableCategories();
    }, 1000);
  }

  weekclass = '';
  monthclass = '';
  threemonthclass = '';
  sixmonthclass = '';
  yearclass = '';

  setBlank() {
    this.weekclass = '';
    this.monthclass = '';
    this.threemonthclass = '';
    this.sixmonthclass = '';
    this.yearclass = '';
  }

  selectedPeriod;
  rentMultiple = 1;

  selectRentPeriod(rentperiod) {
    this.setBlank();
    this.selectedPeriod = rentperiod;
    switch (rentperiod) {
      case '1w':
        this.weekclass = 'activetd';
        this.rentMultiple = 0.2;
        break;
      case '1m':
        this.monthclass = 'activetd';
        this.rentMultiple = 1;
        break;
      case '3m':
        this.threemonthclass = 'activetd';
        this.rentMultiple = 3;
        break;
      case '6m':
        this.sixmonthclass = 'activetd';
        this.rentMultiple = 6;
        break;
      case '1y':
        this.yearclass = 'activetd';
        this.rentMultiple = 12;
        break;
    }
  }

  async gotoproduct(cat) {
    this.quali5lib.dataService.globalvars.set('selectedcategory', cat);
    var subcatlist =
      await this.quali5lib.dataService.getSubcategoriesForCategory(cat.id);
    this.quali5lib.dataService.globalvars.set('subcategories', subcatlist);

    this._router.navigate(['/tabs/productlist']);
  }

  goBack() {
    this.showvideo = false;
    this._router.navigate(['/']);
  }

  gotoservice() {
    this._router.navigate(['/tabs/servicepolicy']);
  }

  gotoprivacy() {
    this._router.navigate(['/tabs/privacypolicy']);
  }

  gotoshipping() {
    this._router.navigate(['/tabs/shippingpolicy']);
  }

  gototerms() {
    this._router.navigate(['/tabs/termscondition']);
  }

  gotocontact() {
    this._router.navigate(['/tabs/contactus']);
  }

  gotopress() {
    this._router.navigate(['/tabs/pressrelease']);
  }
  gotoaboutus() {
    this._router.navigate(['/tabs/aboutus']);
  }

  gotorefernearn() {
    this._router.navigate(['/tabs/refernearn']);
  }

  gotofinsupport() {
    this._router.navigate(['/tabs/financesupport']);
  }

  gotovendorreg() {
    this._router.navigate(['/tabs/vendorregistration']);
  }

  gotoprofile() {
    this._router.navigate(['/tabs/qcprofile']);
  }

  public async displayToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1500,
      position: 'bottom',
      cssClass: 'success-toast',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-circle',
        },
      ],
    });
    toast.present();
  }
  imgsrc;
  vidsrc;

  ionViewWillLeave() {
    this.showvideo = false;
  }

  ngOnDestroy() {
    this.showvideo = false;
  }


  closemodal() {
    setTimeout(() => {
      this.showmodal = false;
    }, 100);
  }
  setmumbai;
  setpune;setdelhi
  loc;
  selectedCity(city) {
    if (city == 'Mumbai') {
      this.setmumbai = true;
      this.setpune = false;
      this.setdelhi = false;
    } else if(city == 'Pune') {
      this.setmumbai = false;
      this.setpune = true;
      this.setdelhi = false;
    } else {
      this.setmumbai = false;
      this.setpune = false;
      this.setdelhi = true;
    }
    this.loc = city;
    console.log(  this.loc );
  }

  unselect() {
    this.loc = '';
    this.setmumbai = !this.setmumbai;
    this.setpune = !this.setpune;
  }

  selectloc() {
  localStorage.setItem('selectedcity', this.loc);
    
    if(this.loc == 'Pune'){
      this.quali5lib.dataService.setCityPricing("1647930041227");
      var prod = this.quali5lib.dataService.getProductForId(this.item.id);
      this.item = prod; 
      this.quali5lib.dataService.globalvars.set('selectedproduct',prod);
    }


    
    if(this.loc == 'Delhi'){
      console.log('Setting price for Delhi..');
      this.quali5lib.dataService.setCityPricing("1660379332422");
      var prod = this.quali5lib.dataService.getProductForId(this.item.id);
      this.item = prod; 
      this.quali5lib.dataService.globalvars.set('selectedproduct',prod);
    }

    
    //var loc = localStorage.getItem('selectedcity')
    //console.log(loc)
    this.closemodal()
  }
  updateCurrentTime() {
    this.currentTime = this.timeService.getCurrentTime();
  }

}
