import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  isBeforeNoon$: Observable<boolean>;
  timeUntilNoon$: Observable<string>;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.isBeforeNoon$ = this.createIsBeforeNoonObservable();
    this.timeUntilNoon$ = this.createTimeUntilNoonObservable();
    
  }

  getCurrentTime(): Date {
    return new Date();
  }

  private createIsBeforeNoonObservable(): Observable<boolean> {
    return timer(0, 1000).pipe(
      map(() => {
        const currentDate = new Date();
        const noon = new Date();
        noon.setHours(12, 0, 0, 0);
        return currentDate < noon;
        
      })
    );
  }
  private createTimeUntilNoonObservable(): Observable<string> {
    return timer(0, 1000).pipe(
      map(() => {
        const noon = new Date();
        noon.setHours(12, 0, 0, 0);
        const currentTime = new Date();
        const timeDifference = noon.getTime() - currentTime.getTime();

        if (timeDifference <= 0) {
          return '12:00 PM has passed.';
        }

        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return `${hours}h ${minutes}m ${seconds}s`;
      })
    );
  }
  baseUrl : string='https://lj7724mli5.execute-api.ap-south-1.amazonaws.com/prod/sendwhatsappmsg';
  wpApi(data){
    
    return this.httpClient.post(this.baseUrl,data).toPromise().then(res=>res);
  }
}
